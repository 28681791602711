<template>
  <div class="radio-button"
          @click="focus"
        @keydown.prevent.enter="$refs.label.click()"
  >
    <input
      v-model="computedValue"
      type="radio"
      ref="input"
      @click.stop
      :disabled="disabled"
      :required="required"
      :name="name"
      :value="nativeValue">
      <label
        ref="label"
      ><slot></slot></label>
  </div>
</template>
<script>
export default {
  props: {
    value: [String, Number, Boolean, Function, Object, Array],
    nativeValue: [String, Number, Boolean, Function, Object, Array],
    type: String,
    disabled: Boolean,
    required: Boolean,
    name: String,
    size: String
  },
  data () {
    return {
      newValue: this.value
    }
  },
  computed: {
    computedValue: {
      get () {
        return this.newValue
      },
      set (value) {
        this.newValue = value
        this.$emit('input', value)
      }
    }
  },
  watch: {
    value (value) {
      this.newValue = value
    }
  },
  methods: {
    focus () {
      this.$refs.input.click()
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/__variables.scss';
.radio-button {
  margin-top: 8px;
  margin-right: 8px;
  label {
    cursor: pointer;
    color: black;
    font-size: 1.2rem;
    border-radius: 8px;
    border: 1px solid $grey97;
    background: white;
    padding: 4px 12px;

  }
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    outline: none;
  }

  input[type="radio"]:checked+label {
    color: white;
    background: $primary;
    border: 1px solid $primary;
  }
}
</style>
