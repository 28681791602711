<template>
  <div class="tag-list">
    <AnswerItem
      v-for="(answer, index) in answers"
      :key="index"
      :name="answer.name || ''"
      :answer="answer"
      :isActive="answer.is_check"
      @onAnswerClick="onAnswerClick"
    />
  </div>
</template>

<script>
import AnswerItem from '@/components/AnswerItem.vue'
export default {
  components: {
    AnswerItem
  },
  props: {
    value: [Array]
  },
  data () {
    return {
      answers: this.value
    }
  },
  computed: {
    computedValue: {
      get () {
        return this.answers
      },
      set (value) {
        this.answers = value
        this.$emit('input', value)
      }
    }
  },
  watch: {
    value (value) {
      this.answers = value
    }
  },
  methods: {
    handleInput () {
      this.$emit('input', this.answers)
    },
    onAnswerClick (answer) {
      this.answers = this.answers.map((obj) => obj.id === answer.id ? { ...obj, is_check: !obj.is_check } : obj)
      this.handleInput()
    }
  }
}
</script>
<style lang="scss" scoped>
.tag-list {
  display: flex;
  flex-wrap: wrap;
}
</style>
